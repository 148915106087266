import React from "react";

const Footer = () => {
  return (
    <div>
      <p className="text-center">&copy; 2020 Yuusuke Okamoto</p>
    </div>
  );
};

export default Footer;
